.page-shipment{
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
}

/* .table-detail-shipment thead tr th {
    background: #dbe5fc !important;
    border: 1px solid #5768b2 !important;
}

.table-detail-shipment th {
    padding: 0.75rem;
}
.table-detail-shipment tr, .table-detail-shipment td{
    padding: 1px !important;
}
.table-detail-shipment tr td input, .table-detail-shipment tr td textarea, .table-detail-shipment tr td select, .table-detail-shipment tr td button{
    border: 0px !important;
    min-height: 50px;
}
.mg5{
    margin: 5px;
}

.table-detail-shipment .form-group {
    margin-bottom: 0px !important;
} 

.table-detail-shipment tr td .btn{
    padding: 5px 24px !important;
}

.table-detail-shipment .form-control:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.1rem rgb(18 20 163 / 99%) !important;
} 

.hiddenRow {
    padding: 0!important;
}
.mgt10{
    margin-top: 10px;
}

td.shipfeebyd {
    align-items: center;
    display: flex;
}
.table-detail-view tr td{
    min-width: 100px;
} */